<template>
  <div class="gallery_video">
    <video class="gallery_video_video" ref="videoElement" @click="toggleFullScreen">
      <source :src="video" type="video/mp4">
    </video>
    <button class="gallery_video_button" @click="toggleFullScreen">
    </button>
  </div>
</template>

<script>
export default {
  name: 'gallery-video',
  props: {
    video: {
      required: true,
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
  },
  methods: {
    toggleFullScreen() {
      const { videoElement } = this.$refs;

      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          videoElement.msRequestFullscreen();
        }

        if (videoElement.paused) {
          videoElement.play();
        }
      }
    },
    handleFullscreenChange() {
      const { videoElement } = this.$refs;

      if (document.fullscreenElement === null && videoElement && !videoElement.paused) {
        videoElement.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../variables';

.gallery_video{

  margin-right: 20px;
  margin-top: 20px;
  width: 250px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 4px 4px 10px $text-color;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_video {
    max-width: 100%;
    max-height: 100%;
  }

  &_button {
    position: absolute;
    background-image: url(../assets/play_button.png);
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    background-color: unset;
    border: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &_button:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
</style>
