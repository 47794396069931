<template>
  <div>
    <div class="gallery">
      <div
        class="gallery_image"
        v-for="(image, i) in images"
        :src="image"
        :style="{backgroundImage: `url(${image})`}"
        :key="`image - ${i}`"
        @click="index = i"
      >
      </div>
      <vue-gallery-slideshow
        :images="images"
        :index="index"
        @close="index = null"
      >
      </vue-gallery-slideshow>
    </div>
    <h2 class="videos_title">{{ $t('video_title') }}</h2>
    <div class="videos">
      <Video v-for="(video, i) in videos"  :key="`video - ${i}`" :video = video />
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Video from '../components/Video.vue';

export default {
  name: 'gallery-page',
  components: {
    VueGallerySlideshow,
    Video,
  },
  data() {
    return {
      images: [
        '/gallery/img1.jpg',
        '/gallery/img2.jpg',
        '/gallery/img3.jpg',
        '/gallery/img4.jpg',
        '/gallery/img5.jpg',
        '/gallery/img6.jpg',
        '/gallery/img7.jpg',
        '/gallery/img8.jpg',
        '/gallery/img9.jpg',
        '/gallery/img10.jpg',
        '/gallery/img11.jpg',
        '/gallery/img12.jpg',
        '/gallery/img13.jpg',
        '/gallery/img14.jpg',
        '/gallery/img15.jpg',
        '/gallery/img16.jpg',
        '/gallery/img17.png',
        '/gallery/img18.png',
      ],
      videos: [
        '/videos/video1.MP4',
        '/videos/video2.MP4',
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss">
@import '../variables';

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $background-color;
  padding: 64px;

  &_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 250px;
    height: 300px;
    border-radius: 8px;
    box-shadow: 4px 4px 10px $text-color;
    transition: transform 0.3s ease;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
  }

  &_image:hover {
    transform: scale(1.1);
  }

}

.videos_title{
  background-color: $background-color;
  color: $title-color;
  margin: 0;
  text-align: center;
  padding-top: 24px;
  font-size: 36px;
  line-height: 46px;
}

.videos{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $background-color;
  padding: 64px;
}
</style>
