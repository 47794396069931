import Vue from 'vue';
import VueI18n from 'vue-i18n';
import am from '../langs/am.json';
import ru from '../langs/ru.json';
import en from '../langs/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallBackLocale: 'ru',
  messages: {
    am,
    en,
    ru,
  },
});

export default i18n;
