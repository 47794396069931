<template>
    <div class="description">
        <h1 class="description_title">{{ $t('description') }}</h1>
        <p class="description_text">
            {{ $t('description_text1') }}
        </p>
        <h2 class="description_block_title">{{ $t('peculiarities') }}</h2>
        <p class="description_text"> <span>{{ $t('microelements') }} -
        </span>{{ $t('microelements_text') }}</p>
        <p class="description_text"> <span>{{ $t('fulvic_acids') }} -
        </span>{{ $t('fulvic_acids_text') }}</p>
        <p class="description_text"> <span>{{ $t('amino_acids') }} -
        </span>{{ $t('amino_acids_text', '') }}</p>

        <h2 class="description_block_title">{{ $t('biostimulator_title') }}</h2>
        <span class="description_biostimulator_title">{{ $t('advantages') }}</span>
        <ul class="description_biostimulator_info">
            <li>
                {{ $t('advantages1') }}
            </li>
            <li>
                {{ $t('advantages2') }}
            </li>
            <li>
                {{ $t('advantages3') }}
            </li>
            <li>
                {{ $t('advantages4') }}
            </li>
            <li>
                {{ $t('advantages5') }}
            </li>
            <li>
                {{ $t('advantages6') }}
            </li>
            <li>
                {{ $t('advantages7') }}
            </li>
            <li>
                {{ $t('advantages8') }}
            </li>
        </ul>

        <span class="description_biostimulator_title">{{ $t('preventive') }}</span>
        <p class="description_preventive_text">{{ $t('preventive_text') }}</p>
        <ul class="description_biostimulator_info">
            <li>
                {{ $t('preventive1') }}
            </li>
            <li>
                {{ $t('preventive2') }}
            </li>
            <li>
                {{ $t('preventive3') }}
            </li>
        </ul>

        <span class="description_biostimulator_title">{{ $t('treatments') }}</span>
        <p class="description_preventive_text">{{ $t('treatments_text') }}</p>

        <h2 class="description_block_title">{{ $t('composition') }} </h2>
        <div class="description_gallery">
            <div
                class="description_gallery_image"
                v-for="(image, i) in currentLanguageCompositionImages"
                :src="image"
                :style="{backgroundImage: `url(${image})`}"
                :key="`image - ${i}`"
                @click="indexComposition = i"
            >
            </div>
            <vue-gallery-slideshow
                :images="currentLanguageCompositionImages"
                :index="indexComposition"
                @close="indexComposition = null"
            >
            </vue-gallery-slideshow>
        </div>

        <h2 class="description_block_title">{{ $t('documents') }} </h2>
        <div class="description_gallery">
            <div
                class="description_gallery_image"
                v-for="(image, i) in imagesDocument"
                :src="image"
                :style="{backgroundImage: `url(${image})`}"
                :key="`image - ${i}`"
                @click="indexDocument = i"
            >
            </div>
            <vue-gallery-slideshow
                :images="imagesDocument"
                :index="indexDocument"
                @close="indexDocument = null"
            >
            </vue-gallery-slideshow>
        </div>

        <h2 class="description_block_title">{{ $t('summary_report') }} </h2>
        <div class="description_gallery">
            <div
                class="description_gallery_image"
                v-for="(image, i) in imagesSummaryReport"
                :src="image"
                :style="{backgroundImage: `url(${image})`}"
                :key="`image - ${i}`"
                @click="indexSummaryReport = i"
            >
            </div>
            <vue-gallery-slideshow
                :images="imagesSummaryReport"
                :index="indexSummaryReport"
                @close="indexSummaryReport= null"
            >
            </vue-gallery-slideshow>
        </div>

        <h2 class="description_block_title">{{ $t('conclusion') }} </h2>
        <div class="description_gallery">
            <div
                class="description_gallery_image"
                v-for="(image, i) in imagesConclusion"
                :src="image"
                :style="{backgroundImage: `url(${image})`}"
                :key="`image - ${i}`"
                @click="indexConclusion = i"
            >
            </div>
            <vue-gallery-slideshow
                :images="imagesConclusion"
                :index="indexConclusion"
                @close="indexConclusion= null"
            >
            </vue-gallery-slideshow>
        </div>
    </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'description-proj',
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      imagesDocument: [
        '/description/img4.jpg',
        '/description/img5.jpg',
        '/description/img6.jpg',
        '/description/img7.jpg',
      ],
      imagesSummaryReport: [
        '/description/img1.jpg',
        '/description/img2.jpg',
        '/description/img3.jpg',
      ],
      imagesConclusion: [
        '/description/img8.jpg',
        '/description/img9.jpg',
        '/description/img10.jpg',
      ],
      imagesOverview: [
        '/description/img8.jpg',
        '/description/img9.jpg',
        '/description/img10.jpg',
      ],
      imagesCompositionEN: [
        '/description/img11.jpg',
        '/description/img12.jpg',
        '/description/img13.jpg',
      ],
      imagesCompositionAM: [
        '/description/img14.jpg',
        '/description/img15.jpg',
        '/description/img16.jpg',
      ],
      indexDocument: null,
      indexSummaryReport: null,
      indexConclusion: null,
      indexComposition: null,
    };
  },
  computed: {
    currentLanguageCompositionImages() {
      const currentLanguage = this.$i18n.locale;
      if (currentLanguage === 'am') {
        return this.imagesCompositionAM;
      }
      return this.imagesCompositionEN;
    },
  },
};
</script>

<style lang="scss">
@import '../variables';

.description{
  padding: 8% 14%;
  background-color: $background-color;

    &_title{
        color: $title-color;
        text-align: center;
    }

    &_block_title{
        color: $title-color;
        text-align: center;
        margin-top: 72px;
    }

    &_text{
        color: $text-color;

        span{
            font-size: 18px;
            font-weight: 700;
        }
    }

    &_peculiarities_title{
        color: $title-color;
        text-align: center;
        margin-top: 40px;
    }

    &_biostimulator_title{
        color: $title-color;
        display: block;
        font-size: 18px;
        font-weight: 700;
        margin-top: 30px;
    }

    &_biostimulator_info{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $text-color;
    }

    &_preventive_text{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $text-color
    }

    &_gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 64px;

        &_image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 150px;
            height: 150px;
            border-radius: 8px;
            box-shadow: 4px 4px 10px $text-color;
            transition: transform 0.3s ease;
            margin-right: 20px;
            margin-top: 20px;
            cursor: pointer;
        }

        &_image:hover {
            transform: scale(1.1);
        }
    }
}
</style>
