<template >
  <div class="homepage_product-info_info">
    <h2 class="homepage_product-info_info_title">{{ $t('product_info_title') }}</h2>
    <div class="homepage_product-info_info_description">
      <p class="homepage_product-info_info_text">
      {{ $t('product_info_text') }}
    </p>
      <p class="homepage_product-info_info_description-text">
        {{ $t('description_text') }}
      </p>
    </div>
    <div class="homepage_product-info_info_features">
      <h2 class="homepage_product-info_info_features_title">  {{ $t('positive_changes') }}</h2>
      <ul class="homepage_product-info_info_features_texts">
        <li>{{ $t('pos_change1') }}</li>
        <li>
          {{ $t('pos_change2') }}
        </li>
        <li>
          {{ $t('pos_change3') }}
        </li>
        <li>
          {{ $t('pos_change4') }}
        </li>
        <li>
          {{ $t('pos_change5') }}
        </li>
        <li>
          {{ $t('pos_change6') }}
        </li>
        <li>
          {{ $t('pos_change7') }}
        </li>
        <li>
          {{ $t('pos_change8') }}
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'homepage-info',
};
</script>

<style lang="scss">
@import '../../../variables';

.homepage_product-info_info{
  width: 48%;

  &_title {
    font-family: "EB Garamond";
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: $title-color;
  }

  &_text {
    font-family: "Lato";
    color: $text-color;
  }

  &_features {

    &_title {
      font-family: "Lato";
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin: 24px 0px;
    }

    &_texts {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: $text-color;
    }
  }

  &_description-title {
    font-family: "Lato";
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $title-color;
    margin: 24px 0px;
  }

  &_description-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $text-color;
  }

  @media (min-width: 320px){
    width: 100%;

    &_title {
      font-size: 30px;
      line-height: 35px;
    }

    &_text {
      font-size: 18px;
      line-height: 23px;
    }

    &_features {

      &_title {
        font-size: 18px;
        line-height: 23px;
        margin: 8px 0px;
    }

      &_texts {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &_description-title {
      font-size: 18px;
      line-height: 23px;
      margin: 8px 0px;
    }

    &_description-text {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px){
    width: 100%;

    &_text {
      font-size: 18px;
      line-height: 24px;
    }

    &_features {

      &_title {
        font-size: 18px;
        line-height: 23px;
        margin: 8px 0px;
      }

      &_texts {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &_description-title {
      font-size: 20px;
      line-height: 24px;
      margin: 12px 0px;
    }

    &_description-text {
      font-size: 14px;
      line-height: 17px;
    }
  }

  @media (min-width: 1025px){
    width: 48%;

    &_title {
      font-size: 60px;
      line-height: 72px;
    }

    &_text {
      font-family: "Lato";
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: $text-color;
    }

    &_features {

      &_title {
        font-family: "Lato";
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: $title-color;
        margin: 24px 0px;
      }

      &_texts {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $text-color;
      }
    }

    &_description {

      &_title {
        font-family: "Lato";
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: $title-color;
        margin: 24px 0px;
      }

      &_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $text-color;
      }
    }
  }
}
</style>
