<template >
  <div class="homepage_langs">
      <span @click="setLocale('en')"> <flag iso="us"></flag></span>
      <!-- <span @click="setLocale('ru')"> <flag iso="ru"></flag></span> -->
      <span @click="setLocale('am')"> <flag iso="am"></flag></span>
  </div>
</template>

<script>
export default {
  name: 'homepage-langs',
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss">

.homepage_langs{
  display: flex;
  position: absolute;
  top: 10px;
  right: 8px;

  span{
    display: block;
    width: 35px;
    height: 25px;
    margin-right: 10px;

    .fi.fis {
      background-size: cover;
        width: 100%;
    }
  }
}

@media (max-width: 769px){
  .homepage_langs{
    top: 16px;
  }
}
</style>
