import Vue from 'vue';
import FlagIcon from 'vue-flag-icon';
import App from './App.vue';
import router from './routes';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;
Vue.use(FlagIcon);
new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
