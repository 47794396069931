<template>
  <div id="app">
    <NavBar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar/NavBar.vue';
import Footer from './components/Footer/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Raleway';
  src: url('@/font/Raleway-Medium.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
}
</style>
