<template>
  <div class="homepage">
    <Background />
    <div class="homepage_product-info">
      <Images />
      <Info />
    </div>
    <ProductBenefits />
  </div>
</template>

<script>
import Background from './components/Background.vue';
import Images from './components/Images.vue';
import Info from './components/Info.vue';
import ProductBenefits from './components/ProductBenefits.vue';

export default {
  name: 'home-proj',
  components: {
    Background,
    Images,
    Info,
    ProductBenefits,
  },
};
</script>

<style lang="scss">
@import '../../variables';

.homepage {

  &_product-info {
    padding: 8% 14%;
    display: flex;
    justify-content: space-between;
    background-color: $background-color;
  }

  @media (min-width: 320px) {

    &_product-info {
      flex-direction: column;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {

    &_product-info {
      padding: 5% 10%;
    }
  }

  @media (min-width: 1025px) {

    &_product-info {
      padding: 6% 14%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $background-color;
    }
  }
}
</style>
