<template>
    <div class="why_us">
        <h2 class="why_us_title">{{ $t('why_us') }}</h2>
        <div class="why_us_item"
            v-for="item in info" :key="item.id"
            :style="{ flexDirection: item.reverse ? 'row-reverse' : 'row' }"
        >
            <p class="why_us_item_text">{{ $t(item.text) }}</p>
            <div class="why_us_item_img"
                :style="{
                    backgroundImage: `url(${item.img})`,
                    marginRight: item.reverse ? '16px' : '0',
                    marginLeft: item.reverse ? '0' : '16px'
                }"
            >
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'why-us',
  data() {
    return {
      info: [
        {
          id: 1,
          text: 'why_text1',
          img: '/product_benefits/img1.jpg',
          reverse: false,
        },
        {
          id: 2,
          text: 'why_text2',
          img: '/product_benefits/img2.jpg',
          reverse: true,
        },
        {
          id: 3,
          text: 'why_text3',
          img: '/product_benefits/img3.jpg',
          reverse: false,
        },
        {
          id: 4,
          text: 'why_text4',
          img: '/product_benefits/img4.jpg',
          reverse: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '../../../variables';

.why_us{
    padding: 2% 14% 8% 14%;
    background-color: $background-color;

    &_title {
        font-family: "Lato";
        text-align: center;
        color: $title-color;
    }

    &_item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 36px;

        &_text {
            font-family: "Lato";
            width: 70%;
            font-weight: 600;
            margin: 0;
            color: $text-color;
        }

    }

    @media (min-width: 320px) {

      &_title {
        font-size: 30px;
        line-height: 35px;
      }

      &_item{

        &_text {
            font-size: 12px;
            line-height: 16px;
        }

        &_img {
            background-size: cover;
            background-position: center;
            width: 100px;
            height: 100px;
            border-radius: 6px;
        }
      }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        padding: 5% 10%;

        &_item{

            &_text {
                font-size: 16px;
                line-height: 18px;
            }

            &_img {
                width: 150px;
                height: 150px;
            }
        }

    }

    @media (min-width: 1025px) {
        padding: 2% 14% 8% 14%;

        &_title {
            font-size: 60px;
            line-height: 72px;
        }

        &_item{

            &_text {
                font-size: 20px;
                line-height: 24px;
            }

            &_img {
                width: 200px;
                height: 200px;
            }
        }
    }
}
</style>
