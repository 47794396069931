<template>
  <div class="homepage_background">
    <p class="homepage_background_content">
      {{ $t('content') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'home-background',
};
</script>

<style lang="scss">
@import '../../../variables';

.homepage_background {
  background-image: url(../../../assets/home/background.gif);
  background-size: cover;
  background-position: center;

  &_content {
    height: 500px;
    margin-bottom: 0;
    margin-top: -190px;
    font-family: "EB Garamond";
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    color: #32a500;
    background-color: #000000a3;
  }

  @media (min-width: 320px){

    &_content {
    font-size: 19px;
    line-height: 29px;
    padding: 0 16px;
    text-align: center;
    }
  }

  @media (min-width: 1025px){

    &_content {
      line-height: 54px;
      font-size: 36px;
    }
  }

}
</style>
