import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/HomePage/index.vue';
import Contact from './views/Contact.vue';
import Gallery from './views/Gallery.vue';
import Description from './views/Description.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/Gallery',
    name: 'Gallery',
    component: Gallery,
  },
  {
    path: '/Description',
    name: 'Description',
    component: Description,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;
