<template>
  <div class="nav_bar">
    <router-link class="nav_bar_logo"  to="/">
      <img  class="nav_bar_logo_img" src="../../assets/logo.png" alt="" />
      <span class="nav_bar_logo_text">{{ $t('logo_text') }}</span>
    </router-link>
    <div class="nav_bar_routes-block">
      <router-link
        to="/"
        class="nav_bar_routes-block_link"
      >
        {{$t('home')}}
      </router-link>
      <router-link
        to="/gallery"
        class="nav_bar_routes-block_link"
      >
        {{ $t('gallery') }}
        </router-link>
      <router-link
        to="/description"
        class="nav_bar_routes-block_link"
      >
        {{ $t('description') }}
      </router-link>
      <router-link
        to="/contact"
        class="nav_bar_routes-block_link"
      >
        {{ $t('contact') }}
      </router-link>
      <Langs />
    </div>
  </div>
</template>

<script>
import Langs from '../../views/HomePage/components/Langs.vue';

export default {
  name: 'nav-bar',
  components: { Langs },
};
</script>

<style lang="scss" scoped>
@import '../../variables';

.nav_bar {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px 0px 16px;
  align-items: center;
  z-index: 2;
  position: relative;

  &_logo{
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    &_img{
      width: 80px
    }

    &_text{
      font-size: 12px;
      text-align: center;
      color: $title-color;
      font-weight: bold;
      white-space: nowrap;
      margin-bottom: 8px;
    }
  }

  &_routes-block {
    display: flex;
    padding: 16px 32px;

    &_link {
      font-weight: 400;
      font-size: 16px;
      color: white;
      text-decoration: none;
      border-radius: 4px;
      padding: 8px 16px;
      background-color: #2ca503;
      margin-right: 20px;
    }
  }

  @media (min-width: 320px) {
    flex-direction: column;

    &_logo{
      margin-bottom: 5px;

      &_img{
        width: 50px
      }
    }

    &_routes-block {
      padding: 8px 4px;
      flex-wrap: wrap;
      justify-content: space-around;

      &_link {
        font-weight: 400;
        font-size: 11px;
        padding: 6px;
        width: 100px;
        text-align: center;
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 769px) {
    flex-direction: column;

    &_routes-block {
      padding: 16px 32px;

      &_link {
        width: unset;
        margin-right: 16px;
        font-weight: 400;
        font-size: 16px;
        padding: 8px 16px;
      }
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    &_logo{

      &_img{
        width: 80px
      }
    }

    &_routes-block {
      padding: 16px 32px;

      &_link {
        margin-right: 16px;
        font-weight: 400;
        font-size: 16px;
        padding: 8px 16px;
      }
    }
  }
}
</style>
