<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_about">
        <h2 class="footer_top_about-title">Ecorise</h2>
        <p class="footer_top_about-info">{{ $t('mail2') }}</p>
        <p class="footer_top_about-info">{{ $t('phone1') }}</p>
      </div>
      <div class="footer_top_links">
        <h2 class="footer_top_links-title"> {{ $t('links') }}</h2>
        <router-link
          to="/" class="footer_top_links-route">{{ $t('home') }}
        </router-link>
        <router-link
          to="/gallery" class="footer_top_links-route">{{ $t('gallery') }}
        </router-link>
        <router-link
          to="/description" class="footer_top_links-route">{{ $t('description') }}
        </router-link>
        <router-link
          to="/contact" class="footer_top_links-route">{{ $t('contact') }}
        </router-link>
      </div>
    </div>
    <div class="footer_bottom">
      <p class="footer_bottom_text">© All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foooter-proj',
};
</script>

<style lang="scss" scoped>
@import '../../variables';

.footer {
  background-color: #354733;
  background-image: url(../../assets/footer.png);
  background-size: cover;
  background-position: center;
  padding: 55px 100px;

  &_top {
    display: flex;
    justify-content: space-around;
    padding: 32px 16px;
    border-bottom: 1px solid #dddea0;
    background-color: $background-color;

    &_about-title {
      font-family: "EB Garamond";
      font-weight: 700;
      font-size: 64px;
      line-height: 84px;
      text-transform: capitalize;
      color: $title-color;
      margin: 0;
    }

    &_about-info {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $text-color;
      margin-bottom: 16px;
    }

    &_links {
      display: flex;
      flex-direction: column;
    }

    &_links-title {
      font-family: "Lato";
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 32px;
      color: $title-color;
    }

    &_links-route {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-decoration: none;
      margin-bottom: 16px;
      color: $text-color;
    }
  }

  &_bottom {
    display: flex;
    justify-content: end;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $background-color;

    &_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: $text-color;
    }
  }

  @media (min-width: 320px){
    padding: 32px 64px;

    &_top {
      display: block;
      padding: 8px 16px;

      &_about-title {
          font-size: 31px;
          line-height: 41px;
        }

      &_about-info {
        font-size: 14px;
      }

      &_links-title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
      }

      &_links-route {
        font-size: 14px;
      }
    }

    &_bottom {
      padding-left: 8px;
      padding-right: 16px;

      &_text {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 1025px){
    padding: 55px 100px;

    &_top {

      &_about-title {
        font-size: 60px;
        line-height: 72px;
      }

      &_links-title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 32px;
      }
    }
  }

  @media (min-width: 769px){
    padding: 55px 100px;

    &_top {
      display: flex;
      padding: 80px 16px;

      &_about-info {
        font-size: 16px;
      }

      &_links-route {
        font-size: 16px;
      }
    }

    &_bottom {
      padding-left: 16px;

      &_text {
        font-size: 16px;
      }
    }
  }
}
</style>
