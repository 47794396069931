<template >
  <div class="homepage_product-info_images">
    <div
        class="homepage_product-info_images_main-image"
        :style="{backgroundImage: `url(${mainImage})`}"
      >
      </div>
    <div class="homepage_product-info_images_images-galery">
      <div v-for="(image, index) in smallImages"
        :style="{backgroundImage: `url(${image})`}"
        :key="index" alt=""
        class="homepage_product-info_images_images-galery_image"
        @click="changeMainImage(index)"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homePage-images',
  data() {
    return {
      mainImage: '/home_images/img1.jpg',
      smallImages: [
        'home_images/img1.jpg',
        'home_images/img2.jpg',
        'home_images/img3.jpg',
      ],
    };
  },
  methods: {
    changeMainImage(index) {
      this.mainImage = this.smallImages[index];
    },
  },
};
</script>

<style lang="scss">

.homepage_product-info_images {
  width: 48%;

  &_main-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    border-radius: 8px;
  }

  &_images-galery {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    &_image {
      width: 150px;
      height: 150px;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 4px;
      &:last-child {
          margin-right: 0;
      }
    }
  }

@media (min-width: 320px){
  width: 100%;

  &_main-image {
    width: 250px;
    height: 250px;
  }

  &_images-galery {
    margin-top: 20px;

    &_image{
      width: 150px;
      height: 90px;
    }
  }
}

@media (min-width: 769px) {

  &_main-image {
    width: 350px;
    height: 300px;
  }

  &_images-galery {
    margin-top: 20px;

    &_image{
      width: 150px;
      height: 150px;
    }
  }
}

@media (min-width: 1025px){
    width: 48%;

    &_images-galery {
      margin-top: 40px;
    }
  }
}
</style>
