<template>
  <div class="contact">
    <div class="contact_img" :style="{backgroundImage: `url(${image})`}"></div>
    <div class="contact_info">
        <h2 class="contact_info_title">{{ $t('contact') }}</h2>
        <div class="contact_info_text" v-for="item in info" :key="item.id">
           <img :src="require(`../assets${item.img}`)" alt="" class="contact_info_icons">
           <p>{{ $t(item.text) }}</p>
        </div>
        <div class="contact_info_mail_block">
          <div class="contact_info_mail" v-for="item in mail" :key="item.id">
            <img :src="require(`../assets${item.img}`)" alt="" class="contact_info_icons">
            <a href="mailto:" class="link">{{ $t(item.text) }}</a>
          </div>
        </div>
        <p class="contact_info_text">{{ $t('director') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-proj',
  data() {
    return {
      image: '/contact/img1.jpg',
      info: [
        {
          id: 1,
          text: 'location',
          img: '/contact/location.png',
        },
        {
          id: 2,
          text: 'phone1',
          img: '/contact/phone.png',
        },
        {
          id: 3,
          text: 'phone2',
          img: '/contact/phone.png',
        },
        {
          id: 4,
          text: 'website',
          img: '/contact/website.png',
        },
      ],
      mail: [
        {
          id: 5,
          text: 'mail1',
          img: '/contact/email.png',
        },
        {
          id: 6,
          text: 'mail2',
          img: '/contact/email.png',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '../variables';

.contact{
  padding: 8% 14%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $background-color;

  &_img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 450px;
    height: 100vh;
    border-bottom-left-radius: 20%;
    border-top-right-radius: 20%;
  }

  &_info{
    margin-left: 20px;
    color: $text-color;

    &_mail_block{
      display: flex;
      flex-direction: column;
    }

    &_mail{
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .link{
        text-decoration: none;
        color: $text-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_text{
      display: flex;
      align-items: center;
    }

    &_icons{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

  }

  @media (min-width: 320px) {
    padding: 5% 7%;

    &_img{
      display: none;
    }

    &_info{

      &_title {
        font-size: 30px;
        line-height: 35px;
      }

      &_mail{
        font-size: 12px;
      }

      &_text{
        font-size: 12px;
      }
    }
  }

  @media (min-width: 769px) {
    padding: 5% 10%;
    &_img{
      display: block;
    }
  }

  @media (min-width: 1025px) {
    padding: 6% 14%;

    &_info{

      &_text{
        font-size: 15px;
      }

      &_info_title{
        font-size: 60px;
        line-height: 72px;
        color: $title-color;
      }

      &_mail{
        font-size: 15px;
      }
    }
  }
}
</style>
